// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-letmeup-js": () => import("./../../../src/pages/letmeup.js" /* webpackChunkName: "component---src-pages-letmeup-js" */),
  "component---src-pages-newsroom-js": () => import("./../../../src/pages/newsroom.js" /* webpackChunkName: "component---src-pages-newsroom-js" */),
  "component---src-pages-product-spec-js": () => import("./../../../src/pages/product-spec.js" /* webpackChunkName: "component---src-pages-product-spec-js" */),
  "component---src-pages-samkiosk-js": () => import("./../../../src/pages/samkiosk.js" /* webpackChunkName: "component---src-pages-samkiosk-js" */),
  "component---src-pages-samorder-food-js": () => import("./../../../src/pages/samorderFood.js" /* webpackChunkName: "component---src-pages-samorder-food-js" */),
  "component---src-pages-samorder-js": () => import("./../../../src/pages/samorder.js" /* webpackChunkName: "component---src-pages-samorder-js" */),
  "component---src-pages-spacebusiness-js": () => import("./../../../src/pages/spacebusiness.js" /* webpackChunkName: "component---src-pages-spacebusiness-js" */)
}

