import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`


@font-face {
    font-family: 'Pretendard-ExtraBold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-SemiBold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'Pretendard-Medium';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Bold';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Pretendard-Black';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Cafe24Shiningstar';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Shiningstar.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

html {
    font-family: 'Pretendard-Bold';
    -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
    margin: 0; 
    padding: 0; 
    text-decoration: none; 
    font-size: 1em; 
    outline: none;
    }
    
    code, kbd, samp, pre, tt, var, textarea, input, select, isindex, listing, xmp, plaintext {
    font: inherit; 
    font-size: 1em; 
    white-space: normal;
    }
    
    dfn, i, cite, var, address, em {
    font-style: normal;
    }
    
    th, b, strong, h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    }
    
    a, img, a img, iframe, form, fieldset, abbr, acronym, object, applet, table {
    border: none;
    }
    
    table {
    border-collapse: collapse; 
    border-spacing: 0;
    }
    
    caption, th, td, center {
    text-align: left; 
    vertical-align: top;
    }
    
    body {
    line-height: 1; 
    background: white; 
    color: black;
    overflow-x: hidden;
    }
    
    q {
    quotes: "" "";
    }
    
    ul,  dir, menu {
    list-style: none;
    }
    
    sub, sup {
    vertical-align: baseline;
    }
    
    a {
    color: inherit;
    }
    
    hr {
    display: none;
    } /* we don't need a visual hr in layout */
    
    font {
    color: inherit !important; 
    font: inherit !important; 
    color: inherit !important;
    } /* disables some nasty font attributes in standard browsers */
    
    marquee {
    overflow: inherit !important; 
    -moz-binding: none;
    }
    
    blink {
    text-decoration: none;
    }
    
    nobr {
    white-space: normal;
    }
    .slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled img,
.slick-next.slick-disabled img {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 2vw;
}

.slick-dots {
  position: absolute;
  bottom: -2vw;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  margin: 0 0.5vw;
  padding: 0;

  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  padding: 0.5vw;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 0.8vw;

  position: absolute;
  top: 0;
  left: 0;

  content: '•';
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}
/* Slider */
div.slick-slider {
  position: relative;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  width: 80vw;
  margin: 0 auto;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  width: 87vw;
  margin: 0 auto;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

div.slick-slide {
  display: none;
  float: left;

  margin: 0;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  width: 4vw;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: flex;
  flex-direction: column;
}
.slick-initialized .slick-slide > div {
  display: flex;
}
.slick-initialized .slick-slide > div > div {
  display: flex !important;
  justify-content: center !important;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}


`;

export default GlobalStyle;
